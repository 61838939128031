.light-blue-bg {
  background-color: #EBEEF2;
}

.light-gray-bg {
  background-color: #f5f5f5;
}

.darkest-blue-bg {
  background-color: $blue-darkest;
}
