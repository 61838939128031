// MATERIALIZE OVERRIDES
.tabs {
  .indicator {
    background-color: $blue-links;
  }

  .tab {

    &:hover {
      background-color: rgba($blue-links, .1);
      transition: all linear 0.3s;
    }

    a {
      color: $blue-links;
      font-weight: 600;
      letter-spacing: 0.4px;

      &:hover {
        color: darken($blue-links, 20%);
      }

      &:focus.active {
        background-color: rgba($blue-links,.2);
      }
    }

    a.active {
      color: $blue-links;
      background-color: rgba($blue-links,.2);
    }

  }
}


.pagination {

  li.active {
      background-color: $blue-links;
  }

  li {
    a {
      &:hover {
        background-color: lighten($blue-links, 20%);
        color: $white;
      }
    }
  }

}

// Blockquote
blockquote {
  border-left: 5px solid #6986B7;
}


// Collapsibles
.keyboard-focused .collapsible-header:focus {
  background-color: $white;
}


// Preloader
.spinner-layer {
  border-color: $white;
}
