// GENERAL CSS

body,
html {
  background-color: $white;
  height: 100%;
}

li,
p {
  font-size: 16px;
}

.bullet-list {
  li {
    list-style-type: disc !important;
    margin: 0 20px 10px;
  }
}

.circle-list {
  li {
    list-style-type: circle !important;
    margin: 0 20px 10px;
  }
}

.no-margin-row {
  margin-bottom: 0;
}

.anchor {
  display: block;
  height: 70px;
  /*same height as header*/
  margin-top: -70px;
  /*same height as header*/
  visibility: hidden;
}

.fc-container {
  max-width: 1280px;
  width: 85%;
  margin: 2% auto;
}

.buffer {
  padding: 16px 0;
}

.max-width-480 {
  max-width: 480px;
  margin: 0 auto;
}

.max-width-540 {
  max-width: 540px;
  margin: 0 auto;
}

.no-margin {
  margin: 0;
}

.no-margin-top {
  margin-top: 0;
}

.margin-top-60 {
  margin-top: 60px;
}

.section-padding {
  padding: 3% 0;
}

.section-subheader {
  font-size: 18px;
  font-weight: 300;
}

.show-on-small {
  @media only screen and (min-width: $small-screen) {
    display: none;
  }
}

.show-on-xl-and-up {
  @media only screen and (max-width: $extra-large-screen) {
    display: none;
  }
}

.hide-on-xl-and-up {
  @media only screen and (min-width: $extra-large-screen-up) {
    display: none;
  }
}

.slim-container {
  max-width: 1280px;
  width: 60%;
  margin: 0 auto;
  @media only screen and (max-width: $medium-screen) {
    width: 75%;
  }
  @media only screen and (max-width: $small-screen) {
    width: 90%;
  }
}

.slimmer-container {
  max-width: 1280px;
  width: 50%;
  margin: 0 auto;
  @media only screen and (max-width: $medium-screen) {
    width: 70%;
  }
  @media only screen and (max-width: $small-screen) {
    width: 90%;
  }
}

.slimmest-container {
  max-width: 1280px;
  width: 40%;
  margin: 0 auto;
  @media only screen and (max-width: $medium-screen) {
    width: 65%;
  }
  @media only screen and (max-width: $small-screen) {
    width: 85%;
  }
}

.btn {
  border-radius: $border-btn-radius;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: all 0.8s ease-in-out;
  font-weight: 700;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
    transform: scale(1.05,1.05);
    -webkit-transform: scale(1.05,1.05);
    -moz-transform: scale(1.05,1.05);
    transition: all 0.4s ease-in-out;

    .mod-messenger-icon {
      fill: $white;
    }
  }
}

.mod-messenger-icon {
  fill: $blue-cta;
  transform: scale(0.8,0.8);
  top: 5px;
  position: relative;
}

.mod-chat-icon {
  fill: $blue-cta;
  top: 5px;
  position: relative;
}

.chat-icon {
  transform: scale(0.9,0.9);
}

.margin-top-10 {
  padding: 10px;
}

.margin-fix {
  margin-left: 5px;
}

.fc-card {
  max-width: 1100px;

  p {
    font-size: 16px;
    padding: 0 20px;
    margin-top: 10px;
  }

  .card {
    height: 320px;
  }
}

.disclaimer-text {
  max-width: 680px;
  margin: 0 auto;
  font-size: 13.5px;
  color: #767676;
  text-align: center;
}

.savings-message {
  font-size: 12px;
  text-align: center;
  margin-top: 0;
}

.savings-message-white {
  font-size: 12px;
  text-align: center;
  margin-top: 0;
  color: white;
}
// HUBSPOT STYLES
// NEW CUNA HOME PAGE
// NEW NAV STYLES

.dropdown-content {
  top: 64px !important;
  background-color: rgba($blue-nav, 0.9);
  width: 300px !important;
  overflow: hidden;

  li {
    a {
      color: white;
    }
  }
}

.dropdown-content li.active,
.dropdown-content li:hover {
  background-color: #194066;
}

.btn-nav {
  background: $blue-links;
  color: $white;

  &:active,
  &:focus,
  &:hover {
    background: darken($blue-links, 5%);
  }
}

nav {
  padding: 0;
  background-color: $blue-nav;
  height: 64px;

  .chat-icon {
    color: $blue-cta;
    fill: $blue-cta;
    width: 20px;
    position: relative;
    top: 5px;
    right: 2px;
    @media only screen and (max-width: 840px) {
      bottom: 7px;
      top: unset;
      left: 0;
    }
  }

  .chat-link {
    display: inline-block;
  }

  .sidenav-trigger {
    i {
      height: 64px;
      line-height: 64px;
    }
    @media only screen and (min-width: 993px) {
      display: inline-block !important;
    }
  }

  .partner-logos {
    padding: 0 30px;
    max-height: 64px;
    margin: 0 10px 0 0;

    a {
      &:hover {
        border-bottom: none;
        margin-top: 0;
        background-color: transparent;
      }
    }

    #mobile-nav-partner-img {
      padding: 0;
    }

    img {
      max-width: 254px;
      max-height: 64px;
      position: relative;
      top: 10px;
    }
    @media only screen and (max-width: 1450px) {
      padding: 0 5px;
    }
    @media only screen and (max-width: 370px) {
      max-width: 200px;

      #mobile-nav-partner-img {
        padding: 0;
        width: 200px;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    .nav-wrapper {
      background-color: white;
    }

    .sidenav-trigger {
      i {
        color: $gray-dark;
      }
    }
  }

  i.right {
    margin-left: 0;
  }

  .mod-messenger-icon {
    fill: $blue-cta;
    transform: scale(0.8,0.8);
    top: 5px;
    position: relative;
  }

  .nav-header {
    font-size: 24px;
    margin-right: 30px;
  }

  ul {
    margin-right: 20px;

    .get-a-quote {
      margin: 0 5px;

      a {
        &:hover {
          border-bottom: 3px solid white;
        }
      }
    }

    li {
      margin: 0 2px;

      a {
        text-transform: uppercase;
        letter-spacing: 1.4px;
        font-weight: 600;
        outline: none;
        transition: all 0.8s ease-in-out;
        max-height: 64px;

        &:hover {
          border-bottom: 4px solid $blue-cta;
          transition: all 0.4s ease-in-out;
          transform: scale(1.05,1.05);
          max-height: 60px;
        }

        &:active {
          border-bottom: 4px solid $blue-cta;
        }
        @media only screen and (max-width: 1500px) {
          padding: 0 5px;
          margin: 0;
        }
      }

      .fa-user-circle {
        font-size: 20px;
      }

      .fa-facebook-messenger {
        font-size: 20px;
        position: relative;
        top: 2px;
        right: 5px;
        color: $blue-cta;
      }

      .fa-phone {
        font-size: 17px;
        position: relative;
        top: 2px;
        right: 5px;
        color: $blue-cta;
        line-height: 2;
      }
    }
  }

  .desktop-nav {
    @media only screen and (max-width: 1283px) {
      display: none;
    }
  }

  .tablet-nav {
    @media only screen and (max-width: 620px) {
      display: none;
    }
    @media only screen and (min-width: 1284px) {
      display: none;
    }

    .tablet {
      @media only screen and (max-width: 840px) {
        display: none;
      }
    }

    .tablet-nav-btn {
      @media only screen and (max-width: 840px) {
        background: #fff;
        padding: 0 10px;
        border-radius: 99px;
        margin: 0 5px;
        height: 40px;
      }

      .mod-messenger-icon {
        @media only screen and (max-width: 840px) {
          bottom: 6px;
          top: unset;
        }
      }

      .fa-phone {
        @media only screen and (max-width: 840px) {
          bottom: 9px;
          top: unset;
          left: 0;
        }
      }
    }
  }

  .mobile-nav {
    @media only screen and (min-width: 621px) {
      display: none;
    }

    .mobile-nav-bottom {
      position: fixed;
      height: 48px;
      background: $white;
      bottom: 0;
      display: flex;
      width: 100%;
      border-top: 3px solid $blue-links;
      text-align: center;

      ul {
        display: flex;
        flex-grow: 1;
        height: 48px;
        margin-right: 0;

        li {
          flex-grow: 1;
        }
      }

      .get-a-quote {
        font-size: 112%;
        text-transform: none;
        height: 48px;
        margin: 0;

        a {
          margin-top: -18px;
          letter-spacing: 0.4px;
        }
      }

      .svg-inline--fa {
        display: inline-block;
        font-size: inherit;
        height: 1.8em;
        overflow: visible;
        vertical-align: middle;
        width: 1.8em;

        &.fa-phone {
          height: 1.6em;
          width: 1.6em;
        }
      }

      a {
        color: $blue-cta;
        font-weight: 600;
        margin-top: -18px;
      }

      .row {
        margin: 0;
        width: 100%;
      }
    }
  }
}
// SIDENAV STYLES

#mobile-sidenav {
  background: $logo-dark;

  .sidenav-img {
    background: url("https://www.forevercar.com/hubfs/ForeverCar.com/slide-out-image.png?t=1531342647084");
    width: 300px;
    height: 150px;
    margin: 0;
    position: relative;
  }

  .tagline {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: 0 1px 4px rgba(0,0,0,.5);
    color: #fff;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 10px;
    left: 50px;
    letter-spacing: 1px;
    line-height: 24px;
  }

  .btn-sidenav {
    width: 100%;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    background: $blue-cta;
    font-size: 12px;
    line-height: 25px;
    font-weight: 900;
    letter-spacing: 1px;
    text-decoration: none;
    color: #fff;
    display: block;
  }

  li {
    a {
      color: $white;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.75px;
    }
  }
}
// NEW CUNA HOME PAGE STYLES

#home {
  #hero {
    .carousel {
      height: 400px !important;
    }

    .hero-header {
      margin-top: 15%;
      z-index: 10;
      position: relative;
      font-size: 32px;
      line-height: 1.2;
      font-weight: 700;
      margin-bottom: 15px;
      padding-top: 10px;
      @media only screen and (max-width: $small-screen) {
        font-size: 28px;
      }
    }

    .amfam-mod {
      font-size: 28px;
      @media only screen and (max-width: $small-screen) {
        font-size: 24px;
      }
    }

    .hero-subheader {
      z-index: 10;
      position: relative;
    }

    .btn-hero {
      margin-bottom: 20px;
    }

    .material-icons {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -moz-font-feature-settings: 'liga';
      -moz-osx-font-smoothing: grayscale;
    }

    .right-indicator {
      position: absolute;
      top: 45%;
      left: 99%;
      max-width: 80px;
      text-align: right;
    }

    .left-indicator {
      position: absolute;
      top: 45%;
      left: 0;
      max-width: 80px;
      text-align: left;
    }

    .indicator-text {
      font-size: 180%;
      color: white;
    }

    a.indicator-text {
      color: white !important;

      &:hover {
        background-color: rgba(0,0,0,0.2);
        border-radius: 99px;
      }
    }

    .content-indicator {
      width: 48px;
      height: 48px;
      background: none;
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      border-radius: 50px;
      margin: 0 5px;
    }

    .hero-container {
      width: 50%;
      margin: 0 auto;
      background: rgba(0,0,0,0.4);
      padding: 0 20px;
      @media only screen and (max-width: $large-screen) {
        width: 70%;
      }
      @media only screen and (max-width: $medium-screen) {
        width: 80%;
      }
      @media only screen and (max-width: $small-screen) {
        width: 90%;
      }
    }

    .hero-background-image {
      height: 400px;
    }

    .background-1 {
      background-image: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/AB-home-hero2.jpg");
      background-attachment: fixed;
      background-position: 0 0;
      background-repeat: no-repeat;
      @media only screen and (max-width: $large-screen) {
        background-attachment: scroll;
        background-position: 0 12%;
      }
      @media only screen and (min-width: $huge-screen) {
        background-position: 50% 0;
      }
    }

    .background-1-rideshare {
      background-image: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/driver4.jpg");
      background-attachment: fixed;
      background-position: 17% 13%;
      background-repeat: no-repeat;
      @media only screen and (max-width: $large-screen) {
        background-attachment: scroll;
        background-position: 70% 15%;
      }
      @media only screen and (min-width: $huge-screen) {
        background-position: 50% 0;
      }
    }

    .background-1-amfam {
      background-image: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/AB-home-hero-12.jpg");
      background-attachment: fixed;
      background-position: 50% 7%;
      background-repeat: no-repeat;
      @media only screen and (max-width: $large-screen) {
        background-size: auto;
        background-position: 60% 10%;
        background-attachment: scroll;
      }
    }

    .background-2-creditunion {
      background-image: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/AB-home-hero1.jpg");
      background-attachment: fixed;
      background-position: 50% 7%;
      background-repeat: no-repeat;
      @media only screen and (max-width: $large-screen) {
        background-size: auto;
        background-position: 60% 10%;
        background-attachment: scroll;
      }
    }

    .background-2-forevercar {
      background-image: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/AB-hero_fishjpg%20(1).jpg");
      background-attachment: fixed;
      background-position: 50% 0;
      background-repeat: no-repeat;
      @media only screen and (max-width: $large-screen) {
        background-size: auto;
        background-position: 15% 20%;
        background-attachment: scroll;
      }
    }

    .background-2 {
      background-image: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/3-hero.jpg");
      background-attachment: fixed;
      background-position: 0 7%;
      background-repeat: no-repeat;
      @media only screen and (max-width: $large-screen) {
        background-size: auto;
        background-position: 20% 10%;
        background-attachment: scroll;
      }
    }

    .background-2-rideshare {
      background-image: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/driver.jpg");
      background-attachment: fixed;
      background-position: 100% 14%;
      background-repeat: no-repeat;
      @media only screen and (max-width: $large-screen) {
        background-position: 30% 14%;
        background-size: auto;
        background-attachment: scroll;
      }
    }

    .background-2-amfam {
      background-image: url("https://cdn2.hubspot.net/hubfs/2592532/2019/Marketing%20Pages/amfam-hero-bg2.jpg");
      background-attachment: fixed;
      background-position: 0 -30%;
      background-repeat: no-repeat;

      @media screen and (-webkit-min-device-pixel-ratio:0) {
         background-position: 0 -30%;
      }

      @-moz-document url-prefix() {
        background-position: 0 60%;
      }

      @media only screen and (max-width: $large-screen) {
        background-size: auto;
        background-position: 40% 100%;
        background-attachment: scroll;
      }
    }

    .background-3 {
      background-image: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/AB-home-hero-12.jpg");
      background-attachment: fixed;
      background-position: 50% 7%;
      background-repeat: no-repeat;
      @media only screen and (max-width: $large-screen) {
        background-size: auto;
        background-position: 60% 10%;
        background-attachment: scroll;
      }
    }

    .background-3-rideshare {
      background-image: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/navigate.jpg");
      background-attachment: fixed;
      background-position: 100% 7%;
      background-repeat: no-repeat;
      @media only screen and (max-width: $large-screen) {
        background-size: auto;
        background-position: 80% 10%;
        background-attachment: scroll;
      }
    }

    .background-3-amfam {
      background-image: url("https://cdn2.hubspot.net/hubfs/2592532/2019/Marketing%20Pages/amfam-hero-bg-3.jpg");
      background-attachment: fixed;
      background-position: 50% 7%;
      background-repeat: no-repeat;
      @media only screen and (max-width: $large-screen) {
        background-size: auto;
        background-position: 30% 10%;
        background-attachment: scroll;
      }
    }

    .background-4 {
      background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/AB-video-hero2.jpg");
      background-repeat: no-repeat;
      background-size: cover;

      .hero-header {
        margin-top: 30px;
        font-size: 32px;
        margin-bottom: 10px;
        line-height: 1.2;
        @media only screen and (max-width: $small-screen) {
          font-size: 26px;
          max-width: 300px;
          margin: 20px auto 10px;
        }
      }

      .hero-subheader {
        font-size: 28px;
        font-weight: 300;
        margin: 0 0 20px;
        line-height: 1.2;
        @media only screen and (max-width: $small-screen) {
          font-size: 21px;
          max-width: 300px;
          margin: 0 auto 10px;
          font-weight: 400;
        }
      }

      .video-still {
        width: 220px;
        border: 2px solid $white;
        margin-top: 0;
        transition: all 0.8s ease-in-out;

        &:hover {
          transform: scale(1.05,1.05);
          -webkit-transform: scale(1.05,1.05);
          -moz-transform: scale(1.05,1.05);
          transition: all 0.4s ease-in-out;
          border: 2px solid $blue-cta;
        }
      }
    }
  }

  #trust {
    border-top: 4px solid $blue-cta;
    background-color: $blue-light-bg;
    padding: 0 0 25px;

    .amfam-logo-container {
      padding-top: 20px;

      img {
        max-width: 420px;
        width: 100%;
      }
    }

    .section-header {
      font-weight: 300;
      font-size: 220%;
      padding-top: 20px;
      margin: 0 auto 10px;
      max-width: 900px;
      line-height: 1.3;
    }

    .phone-icon {
      font-size: 16px;
    }

    .chat-icon {
      position: relative;
      top: 2px;
      font-size: 17px;
    }

    .section-subheader {
      margin: 10px 0 0;
      font-size: 160%;
      line-height: 1.3;
      max-width: 620px;
      margin: 0 auto;
    }

    .amfam-mod {
      max-width: 820px;
    }

    .amfam-mod-p {
      padding-top: 10px;
      margin: 10px auto 0;
      max-width: 772px;
    }

    .btn {
      width: 200px;
      margin-bottom: 20px;
      padding: 0;
      box-shadow: none;
    }

    .btn-container {
      max-width: 640px;
      margin: 20px auto;
    }

    .section-container {
      max-width: 1280px;
      width: 60%;
      margin: 0 auto;
      @media only screen and (max-width: $large-screen) {
        width: 60%;
      }
      @media only screen and (max-width: $medium-screen) {
        width: 75%;
      }
      @media only screen and (max-width: $small-screen) {
        width: 90%;
      }
    }
  }

  #problem-solution {
    .link-arrow {
      font-size: 15px;
    }

    h4 {
      font-size: 24px;
      line-height: 1.2;
      font-weight: 300;
    }

    img {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
      padding: 20px;
    }

    ul {
      margin: 0 0 0 20px;

      li {
        margin-bottom: 10px;
      }

      li:before {
        font-family: "Font Awesome 5 Pro";
        content: '\f00c'!important;
        margin: 0 5px 0 -20px;
        fill: #00b188;
        color: #00b188;
        font-weight: 900;
      }
    }
  }

  #how-it-works {
    padding: 3% 0;
    // background: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/shutterstock_1095417968.jpg");
    background-repeat: repeat;
    background-size: contain;
    background-image: linear-gradient(to bottom, rgba($logo-dark, 0.85), rgba($logo-dark, 0.85)), url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/shutterstock_1095417968.jpg");
    background-image: -moz-linear-gradient(top, rgba($logo-dark, 0.85), rgba($logo-dark, 0.85)), url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/shutterstock_1095417968.jpg");
    background-image: -o-linear-gradient(top, rgba($logo-dark, 0.85), rgba($logo-dark, 0.85)), url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/shutterstock_1095417968.jpg");
    background-image: -ms-linear-gradient(top, rgba($logo-dark, 0.85), rgba($logo-dark, 0.85)), url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/shutterstock_1095417968.jpg");
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba($logo-dark, 0.85)), to(rgba($logo-dark, 0.85))), url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/shutterstock_1095417968.jpg");
    background-image: -webkit-linear-gradient(top, rgba($logo-dark, 0.85), rgba($logo-dark, 0.85)), url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/shutterstock_1095417968.jpg");
    background-attachment: fixed;

    h3 {
      font-weight: 400;
      font-size: 300%;
      margin-bottom: 0;
    }

    p {
      font-size: 15px;
      padding: 0 16px;
      @media only screen and (max-width: $extra-large-screen) {
        margin-top: 7px;
      }
    }

    .section-container {
      max-width: 1280px;
      width: 60%;
      margin: 0 auto;
      @media only screen and (max-width: $large-screen) {
        width: 70%;
      }
      @media only screen and (max-width: $small-screen) {
        width: 90%;
      }
    }

    .card {
      height: 320px;
    }

    .savings-message-white {
      font-size: 12px;
      text-align: center;
      margin-top: 0;
      color: white;
    }

    .white-link {
      color: white;
    }
  }

  #know-the-difference {
    background-color: $white;
    padding-bottom: 4%;

    .row {
      margin-bottom: 10px;
    }

    .section-subheader {
      margin-top: 0;
      letter-spacing: 0.4px;
      max-width: 720px;
      margin: 10px auto;
      text-align: center;
      @media only screen and (max-width: $medium-screen) {
        text-align: left;
      }
    }

    .carousel-container {
      max-height: 320px;
      max-width: 700px;
      margin: 0 auto;
      padding-bottom: 1%;
      @media only screen and (max-width: $small-screen) {
        height: 460px;
        max-height: 480px;
      }
      @media only screen and (max-width: $medium-screen) {
        max-height: 420px;
        height: 400px;
      }
      @media only screen and (max-width: $small-screen) {
        max-height: 440px;
        height: 440px;
      }
      @media only screen and (max-width: $hvga-screen) {
        max-height: 460px;
        height: 460px;
      }

      .carousel {
        @media only screen and (max-width: $small-screen) {
          height: 440px !important;
        }
        @media only screen and (max-width: $hvga-screen) {
          height: 460px !important;
        }
      }

      p {
        margin: 0;
      }

      .material-icons {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -moz-font-feature-settings: 'liga';
        -moz-osx-font-smoothing: grayscale;
      }

      .middle-indicator {
        position: absolute;
        top: 10%;
      }

      .middle-indicator-text {
        font-size: 200%;
      }

      a.middle-indicator-text {
        color: white !important;
      }

      .content-indicator {
        width: 64px;
        height: 64px;
        background: none;
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        border-radius: 50px;
      }

      .carousel-header {
        font-size: 240%;
        padding: 50px;
        line-height: 1.2;
        @media only screen and (max-width: $small-screen) {
          padding: 30px;
        }
      }

      .carousel-content {
        font-size: 16px;
        max-width: 600px;
        margin: 0 auto;
        background-color: #fff;
        padding: 15px 20px;
      }

      .carousel-1 {
        .carousel-header {
          background-color: $blue-nav;
        }
      }

      .carousel-2 {
        .carousel-header {
          background-color: $primary-green;
        }
      }

      .carousel-3 {
        .carousel-header {
          background-color: lighten($gold-dark, 10%);
        }
      }
    }
  }

  #reviews-home {
    padding: 30px 0;
    background-color: $blue-light-bg;

    .section-header {
      @media only screen and (max-width: $extra-large-screen) {
        max-width: 600px;
        margin: 0 auto;
      }
    }

    .row {
      margin-bottom: 0;
    }

    .review-p {
      font-size: 128%;
      line-height: 1.4;
      font-weight: 300;
    }

    .btn {
      margin: 5px 0 20px;
    }
  }

  #awards {
    padding: 3% 0;
    background-color: $white;

    h3 {
      max-width: 700px;
      margin: 0 auto;
    }
  }

  #save-money {
    padding: 3% 0;
    background-color: $blue-light-bg;

    .side-header {
      font-size: 20px;
      margin-top: 14px;
      line-height: 1.3;
      color: $blue-cta;
    }

    .link-arrow {
      font-size: 15px;
    }
  }
}

#curve {
  background-color: #f1f1f1;

  #curveUpColor path {
    fill: $blue-dark;
    stroke: $blue-dark;
  }
}

footer {
  background-color: $blue-darker;

  .footer-nav {
    display: inline-block;
    margin: 10px 0 0;

    .footer-nav-item {
      float: left;
      margin: 0 5px;
      @media only screen and (max-width: $small-screen) {
        float: none;
        margin: 10px;
      }

      .footer-nav-link {
        padding: 0 15px;
        display: inline-block;
      }
    }
  }

  .legal-link {
    font-size: 12px;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
  }

  .row {
    margin-bottom: 0 !important;
  }
  @media only screen and (max-width: 620px) {
    padding-bottom: 50px;
  }

  .sub-footer {
    background-color: $blue-cta;
    width: 100%;
    height: 140px;

    .trust-image-container {
      max-width: 800px;
      padding: 10px 0;
      margin: 0 auto;

      .trustpilot {
        width: 170px;
        padding-top: 20px;
      }

      .bbb {
        width: 150px;
        padding-top: 20px;
      }

      .consumeraffairs {
        width: 240px;
        padding-top: 20px;
      }
    }
    @media only screen and (max-width: $small-screen) {
      height: 340px;
    }
  }

  a {
    font-weight: 600;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    transition: all 0.8s ease-in-out;
    font-size: 15px;

    &:hover {
      color: $blue-cta;
      transform: scale(1.05, 1.05);
      -webkit-transform: scale(1.05, 1.05);
      -moz-transform: scale(1.05, 1.05);
      transition: all 0.4s ease-in-out;
    }
  }

  .footer-map {
    padding: 20px 0 0;
    max-width: 920px;
    margin: 0 auto;

    .col {
      @media only screen and (max-width: $small-screen) {
        padding-bottom: 14px;
      }
    }
  }

  .footer-compliance {
    color: $white;
    margin-bottom: 5px;
    line-height: 1.4;
    font-weight: 400;
    font-size: 15px;

    .legal-link {
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
    }

    .legal-link-top {
      padding-top: 10px;
    }
  }

  .compliance-links-container-cuna {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 30px;

    .privacy {
      @media only screen and (max-width: $small-screen) {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 14px;
      }
    }

    .terms {
      text-align: right;
      @media only screen and (max-width: $small-screen) {
        text-align: center;
        padding-bottom: 14px;
      }
    }

    a {
      font-weight: 600;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      font-weight: 600;

      &:hover {
        color: $blue-cta;
      }
    }
  }

  .compliance-links-container {
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 30px;

    .privacy {
      padding-top: 12px;
      @media only screen and (max-width: $small-screen) {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 14px;
      }
    }

    .terms {
      padding-top: 12px;
      @media only screen and (max-width: $small-screen) {
        text-align: center;
        padding-bottom: 14px;
      }
    }

    a {
      font-weight: 600;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      font-weight: 600;

      &:hover {
        color: $blue-cta;
      }
    }
  }

  .footer-copyright-container {
    background-color: $blue-darkest;
    padding: 20px 0;

    ul {
      margin: 0;
      @media only screen and (max-width: $small-screen) {
        text-align: center;
      }

      li {
        display: inline-block;
        font-size: 15px;
        color: white;
        margin: 10px 20px 10px 0;
        letter-spacing: 1.4px;

        a {
          display: inline-block;
        }
      }
    }

    .social-icons {
      text-align: right;
      @media only screen and (max-width: $small-screen) {
        text-align: center;
        margin-top: 20px;
      }

      a {
        margin-right: 10px;
        display: inline-block;

        &:hover {
          transform: scale(1.1,1.1);
        }

        img {
          max-width: 40px;
        }
      }
    }
  }
}
// GETTING STARTED PAGE STYLES

#getting-started-desktop {
  // height: 90vh;
  //
  // @media only screen and (max-width: $large-screen) {
  //   height: 105vh;
  // }
  .carousel {
    height: 840px !important;
    @media only screen and (max-width: $medium-screen) {
      height: 1000px !important;
    }
  }

  .cityscape {
    img {
      width: 100%;
      max-height: 500px;
      max-width: 1000px;
    }
  }

  .explore-quote-container {
    max-width: 900px;
    margin: 0 auto;
  }

  .btn {
    margin-bottom: 20px;
  }

  .screenshot-img {
    width: 100%;
    max-width: 200px;
  }

  .inspection-img {
    max-width: 400px;
    width: 100%;
  }

  .phone-icon {
    font-size: 16px;
  }

  .chat-icon {
    position: relative;
    top: 2px;
    font-size: 17px;
  }

  .valign-wrapper {
    @media only screen and (max-width: $medium-screen) {
      display: inline-block;
    }
  }

  .section-header {
    font-size: 240%;
    font-weight: 700;
  }

  .carousel-p {
    font-size: 140%;
    font-weight: 400;
    margin: 10px 0 20px;
    line-height: 1.4;
  }

  .protectionator-icon {
    max-width: 38px;
    margin-top: 18px;
    padding-right: 12px;
  }

  .protectionator-tip {
    font-size: 14px;
    line-height: 1.3;
  }

  .link-blue {
    color: $blue-dark;
    text-transform: uppercase;
    font-size: 130%;
    letter-spacing: 0.4px;

    &:hover {
      color: darken($blue-dark,5%);
    }
  }

  .carousel-1 {
    background-color: $yellow;

    .btn {
      margin: 5px;
    }

    .valign-wrapper {
      @media only screen and (max-width: $medium-screen) {
        display: inline-block;
      }
    }
  }

  .carousel-2 {
    background-color: $blue-darkest;

    .btn {
      margin: 5px;
    }
  }

  .carousel-3 {
    background-color: $blue-cta;

    .btn {
      margin: 5px;
      border: 1px solid $white;
    }
  }

  .carousel-4 {
    background-color: #00b288;

    .btn {
      margin: 5px;
    }
  }

  .carousel-inspection {
    background-color: $blue-dark;

    .btn {
      margin: 5px;
    }
  }

  .carousel-5 {
    background-color: $blue-nav;

    .screenshot-img {
      max-width: 400px;
    }

    .btn {
      margin: 5px;
    }

    ul {
      margin: 0 0 0 20px;

      li {
        margin-bottom: 10px;
      }

      li:before {
        font-family: "Font Awesome 5 Pro";
        content: '\f00c'!important;
        margin: 0 5px 0 -20px;
        fill: #00b188;
        color: #00b188;
        font-weight: 900;
      }
    }
  }

  .carousel-6 {
    background-color: $plum-dark;

    h3 {
      font-weight: 700;
    }

    .btn {
      width: 200px;
      margin-bottom: 20px;
      padding: 0;
      box-shadow: none;
    }

    .btn-container {
      max-width: 640px;
      margin: 0 auto;
    }
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
  }

  .left-indicator {
    position: absolute;
    top: 50%;
    left: 0;
    max-width: 80px;
    text-align: left;
  }

  .right-indicator {
    position: absolute;
    top: 50%;
    left: 99%;
    max-width: 80px;
    text-align: right;
  }

  .indicator-text {
    font-size: 42px;
  }

  a.indicator-text {
    color: white !important;

    &:hover {
      background-color: rgba(0,0,0,0.2);
      border-radius: 99px;
    }
  }

  .content-indicator {
    width: 64px;
    height: 64px;
    background: none;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
  }
}

#getting-started-mobile {
  .container-lite {
    width: 90%;
    margin: 0 auto;
  }

  .chat-icon {
    position: relative;
    top: 2px;
    font-size: 17px;
  }

  .row {
    margin-bottom: 0;
    padding-bottom: 20px;
  }

  .screenshot-img {
    width: 100%;
    max-width: 200px;
    @media only screen and (max-width: $small-screen) {
      max-width: 300px;
    }
  }

  .inspection-img {
    max-width: 400px;
    width: 100%;
  }

  .carousel {
    height: 840px !important;
    @media only screen and (max-width: $medium-screen) {
      height: 1000px !important;
    }
  }

  .cityscape {
    img {
      width: 100%;
      max-height: 500px;
      max-width: 1000px;
    }
  }

  .btn {
    margin-bottom: 20px;
  }

  .valign-wrapper {
    @media only screen and (max-width: $small-screen) {
      display: inline-block;
    }
  }

  .section-header {
    font-size: 240%;
    font-weight: 700;
  }

  .carousel-p {
    font-size: 140%;
    font-weight: 400;
    margin: 10px 0 20px;
    line-height: 1.4;
  }

  .protectionator-icon {
    max-width: 38px;
    margin-top: 18px;
    padding-right: 12px;
  }

  .protectionator-tip {
    font-size: 14px;
    line-height: 1.3;
  }

  .link-blue {
    color: $blue-dark;
    text-transform: uppercase;
    font-size: 130%;
    letter-spacing: 0.4px;

    &:hover {
      color: darken($blue-dark,5%);
    }
  }

  .carousel-1 {
    background-color: $yellow;

    .btn {
      margin: 5px;
    }
  }

  .carousel-2 {
    background-color: $blue-darkest;

    .screenshot-img {
      padding: 10px 0 0;
    }

    .btn {
      margin: 5px;
    }
  }

  .carousel-3 {
    background-color: $blue-cta;

    .screenshot-img {
      padding: 10px 0 0;
    }

    .btn {
      margin: 5px;
      border: 1px solid $white;
    }
  }

  .carousel-4 {
    background-color: #00b288;

    .screenshot-img {
      padding: 10px 0 0;
    }

    .btn {
      margin: 5px;
    }

    .slimmer-container {
      @media only screen and (max-width: $medium-screen) {
        width: 80%;
      }
    }
  }

  .carousel-inspection {
    background-color: $blue-dark;
  }

  .carousel-5 {
    background-color: $blue-nav;

    .screenshot-img {
      max-width: 400px;
    }

    .btn {
      margin: 5px;
    }

    ul {
      margin: 0 0 0 20px;

      li {
        margin-bottom: 10px;
      }

      li:before {
        font-family: "Font Awesome 5 Pro";
        content: '\f00c'!important;
        margin: 0 5px 0 -20px;
        fill: #00b188;
        color: #00b188;
        font-weight: 900;
      }
    }
  }

  .carousel-6 {
    background-color: $plum-dark;

    h3 {
      font-weight: 700;
    }

    .btn {
      width: 200px;
      margin-bottom: 20px;
      padding: 0;
      box-shadow: none;
    }

    .btn-container {
      max-width: 640px;
      margin: 0 auto;
    }

    .link-faq {
      a {
        font-size: 140%;
        font-weight: 700;

        &:hover {
          color: darken($blue-links, 10%);
        }
      }
    }
  }
}

.plan-level-row {

  .plan-level-img {
    width: 100%;
    max-width: 160px;
  }

  .coverage-info-body {
    font-size: 15px;
    margin: 10px 0;
  }

  hr {
    background: #d2d2d2;
    border: 0 solid transparent;
    display: block;
    height: 1px;
    margin: 20px 0;
  }

  .row {
    margin-bottom: 0;
  }

  .coverage-info-header {
    font-size: 21px;
    margin: 0;
  }

  .coverage-bar,
  .coverage-bar-bg {
    border-radius: 99px;
    height: 20px;
  }

  .coverage-bar-bg {
    background: #DDD;
    overflow: hidden;
    width: 100%;
    max-width: 240px;
    position: relative;
    display: flex;
  }

  .silver-bar {
    background-color: #919EAA !important;
    background-image: linear-gradient(-180deg, #F2F5F7 0%, #B3BCC5 100%);
    width: 60%;
  }

  .gold-bar {
    background-color: #FFE9A0 !important;
    background-image: linear-gradient(0deg, #F7BD4A 0%, #FFE9A0 100%);
    width: 80%;
  }

  .platinum-bar {
    background-color: #253D53 !important;
    background-image: linear-gradient(-180deg, #9FBDD7 0%, #506A82 100%);
    width: 90%;
  }

  .platinum-plus-bar {
    background-image: linear-gradient(-180deg, #5DEDCC 0%, #00B188 100%);
    width: 95%;
  }

  .plus-ball {
    background-image: linear-gradient(-90deg, $plan-platinum-plus, $plan-platinum-plus-dark);
    color: #FFFFFF;
    font-weight: bold;
    font-size: 24px;
    line-height: 0.9;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    right: 15px;
    text-align: center;
  }

  .coverage-info-header-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    .coverage-info-header {
      font-size: 21px;
    }
  }
}

.additional-benefits-monthly {
  h3 {
    margin: 40px 0 20px;
  }

  .additional-benefits-row {
    @media only screen and (min-width: 601px) {
      display: flex;
      align-items: center;
    }
  }

  .benefits-img {
    width: 100%;
    max-width: 160px;
    margin-top: 8px;
  }

  .benefits-header {
    font-weight: 700;
    font-size: 21px;
    margin: 0;
  }

  .benefits-content {
    font-size: 15px;
    margin: 0 0 10px;
  }
}

.plan-level-carousel-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1% 1% 3%;
  @media only screen and (max-width: $small-screen) {
    max-height: 400px;
  }

  p {
    margin: 0;
  }

  .carousel {
    height: 270px !important;
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
  }

  .middle-indicator {
    position: absolute;
    top: 15%;
  }

  .middle-indicator-text {
    font-size: 170%;
  }

  a.middle-indicator-text {
    color: white !important;

    &:hover {
      background-color: rgba(0,0,0,0.2);
      border-radius: 99px;
    }
  }

  .content-indicator {
    width: 48px;
    height: 48px;
    background: none;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
  }

  .carousel-header {
    font-size: 240% !important;
    padding: 50px;
    line-height: 1.2;
    @media only screen and (max-width: $small-screen) {
      padding: 30px;
    }
  }

  .carousel-content {
    font-size: 16px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    padding: 15px 20px;
    height: 162px;
  }

  .carousel-1-cuna {
    .carousel-header {
      background: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/plan-levels_0000_silver.jpg");
    }
  }

  .carousel-2-cuna {
    .carousel-header {
      background: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/plan-levels_0002_gold.jpg");
    }
  }

  .carousel-3-cuna {
    .carousel-header {
      background: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/plan-levels_0001_platinum.jpg");
    }
  }

  .carousel-4-cuna {
    .carousel-header {
      background: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/plan-levels_0001_platinum-plus.jpg");
    }

    .platinum-plus {
      font-weight: 700;
      font-size: 80%;
    }
  }

  .carousel-1,
  .carousel-2,
  .carousel-3 {
    .carousel-header {
      height: 143px;
    }
  }
}

#plan-and-coverage-details {
  background-color: $blue-light-bg;

  .section-padding {
    padding-top: 60px;
  }

  .coverage-header {
    font-size: 180%;
    margin-bottom: 0;
  }

  .section-subheader {
    font-weight: 400;
    margin: 0 0 10px;
    font-size: 21px;
  }

  hr {
    background: #DDDDDD;
    border: 0 solid transparent;
    display: block;
    height: 1px;
    margin: 30px 0 45px;
  }

  .coverage-container {
    margin: 15px 0;
  }

  .coverage-graphic-container {
    display: flex;
    max-width: 540px;
    margin: 0 auto;
    padding: 20px 60px 10px;

    .coverage-level-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;
      max-width: 120px;
      margin: 0 auto;
      background: white;
    }

    .coverage-level-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;
      max-width: 160px;
      width: 100%;
      margin: 0 auto;
    }

    .coverage-level-name {
      font-size: 16px;
      margin: 0 0 2px;
      font-weight: 600;
      line-height: 1.2;
    }

    .coverage-level-description {
      font-size: 12px;
      margin: 4px 0 10px;
    }

    .vehicle-care-coverage-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;
      max-width: 200px;
      width: 100%;
      margin: 0 auto;

      .vehicle-care-coverage-row {
        position: relative;
        bottom: 24px;
      }

      .vehicle-care-plus-icon {
        position: relative;
        bottom: 14px;
        left: 2px;
        color: #AAA;
        font-weight: 700;
        font-size: 20px;
      }

      .material-icons-outlined {
        font-size: 42px;
        line-height: 0.8;
      }
    }

    .warranty-svg {
      max-width: 50px;
      position: relative;
      top: 12px;
      margin: 0 2px;
    }

    .vehicle-care-icon {
      color: $gear-yellow;
    }

    .platinum-mc-icon {
      color: $gear-blue;
    }

    .powertrain-icon {
      color: $gear-pink;
    }

    .gears-container {
      max-width: 90px;
      margin: 0 auto 4px;

      .material-icons-outlined {
        font-size: 42px;
        line-height: 0.8;
      }
    }
  }

  .level-details-container {
    .details-header {
      font-size: 24px;
      margin: 10px 0 4px;
      line-height: 1.2;
    }

    .details-content {
      margin-top: 0;
    }
  }

  ul {
    margin: 10px 0 0 20px;

    li {
      margin-bottom: 10px;
    }

    li:before {
      font-family: "Font Awesome 5 Pro";
      content: '\f00c'!important;
      margin: 0 5px 0 -20px;
      fill: #00b188;
      color: #00b188;
      font-weight: 900;
    }
  }
}

#faq {
  .question {
    font-size: 160%;
    font-weight: 400;
    color: $blue-cta;
    line-height: 1.4;
    margin-bottom: 0;
  }

  .answer {
    margin-top: 5px;
    line-height: 1.7;
    font-size: 120%;
  }

  .col {
    padding: 0 40px;
  }

  .faqs-link {
    margin-top: 5px;
    line-height: 1.7;
    font-size: 120%;
    color: $blue-dark;
  }
}
// REPAIR EVENTS PAGE STYLES

#repair-events {
  #hero {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-image: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/AB-repair-event-hero2.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 10%;
    height: 240px;

    .header-container {
      width: 100%;
      background-color: rgba(0,0,0,0.5);
      padding: 10px;
    }
    @media only screen and (max-width: $large-screen) {
      background-attachment: scroll;
      background-size: cover;
      background-position: center;
    }
    @media only screen and (min-width: $large-screen) {
      height: 300px;
    }

    h1 {
      margin: 0;
      font-weight: 300;
      font-size: 300%;
    }
  }

  #streamline {
    .row {
      margin-bottom: 0;
    }

    .section-subheader {
      font-size: 117%;
      font-weight: 400;
      line-height: 1.55;
      max-width: 800px;
      margin: 20px auto 0;
    }
  }

  #protectionator {
    background-color: $blue-light-bg;

    .section-header {
      line-height: 1.15;
      margin-bottom: 0;
    }

    .btn-container {
      max-width: 440px;
      margin: 0 auto;
    }

    .btn {
      width: 200px;
      margin-bottom: 20px;
      padding: 0;
    }

    .chat-icon {
      font-size: 17px;
      position: relative;
      top: 2px;
    }

    h4 {
      font-weight: 300;
    }

    p {
      font-size: 16px;
      padding: 0 20px;
    }

    .section-container {
      max-width: 1100px;
      width: 60%;
      margin: 0 auto;
      @media only screen and (max-width: $large-screen) {
        width: 70%;
      }
      @media only screen and (max-width: $medium-screen) {
        width: 80%;
      }
      @media only screen and (max-width: $small-screen) {
        width: 90%;
      }
    }

    .card {
      height: 300px;
    }

    .card-row {
      width: 100%;
      max-width: 760px;
      @media (max-width:$medium-screen) and (min-width:$small-screen) {
        width: 65%;
      }
    }
  }

  #responsibilities {
    .section-container {
      width: 65%;
      margin: 0 auto;
      max-width: 1100px;
      @media only screen and (max-width: $large-screen) {
        width: 85%;
      }
    }

    ul {
      margin: 0 0 0 20px;
      max-width: 480px;

      li {
        margin-bottom: 10px;
        font-size: 117%;
      }

      li:before {
        font-family: "Font Awesome 5 Pro";
        content: '\f00c'!important;
        margin: 0 5px 0 -20px;
        fill: #00b188;
        color: #00b188;
        font-weight: 900;
      }
    }

    p {
      font-size: 117%;
    }

    a {
      &:hover {
        color: darken($blue-links, 20%);
      }
    }

    .card {
      background: #f1f4f8;
      padding: 8px 25px;
    }
  }
}
// BENEFITS SECTION

#benefits {
  #hero {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-image: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/AB-home-hero3.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 7%;
    height: 240px;

    .header-container {
      width: 100%;
      background-color: rgba(0,0,0,0.5);
      padding: 10px;
    }
    @media only screen and (max-width: $large-screen) {
      background-attachment: scroll;
      background-size: cover;
      background-position: center;
    }
    @media only screen and (min-width: $large-screen) {
      height: 300px;
    }
    @media only screen and (min-width: $extra-large-screen) {
      height: 350px;
    }

    h1 {
      margin: 0;
      font-weight: 300;
      font-size: 300%;
    }
  }

  #perks {
    padding: 1.5% 0 1%;

    .amfam-mod {
      font-size: 32px;
    }

    img {
      max-width: 400px;
      width: 100%;
    }

    .perks-content {
      font-size: 117%;
      line-height: 1.55;
    }

    .disclaimer-text {
      margin-top: 40px;
    }
  }

  #reviews-benefits {
    background: $white;

    .section-subheader {
      font-size: 21px;
    }
  }

  #better-choice {
    background-color: $blue-light-bg;

    .section-subheader {
      font-size: 21px;
      max-width: 540px;
      margin: 0 auto;
    }

    h4 {
      font-weight: 300;
    }

    .card {
      height: 260px;
    }

    .card-row {
      width: 100%;
      max-width: 900px;
      @media (max-width:$medium-screen) and (min-width:$small-screen) {
        width: 65%;
      }
    }
  }

  #protectionator-difference {
    background-color: $plum-dark;

    .protectionator-dots {
      .row {
        margin: 0 0 10px 12px;

        .s11 {
          font-weight: 600;
        }
      }

      img {
        width: 16px;
        position: relative;
        top: 3px;
      }

      p {
        font-size: 15px;
        font-weight: 600;
      }
    }

    .protectionator-cta-container {
      max-width: 540px;
      margin: 0 auto;
    }

    .chat-icon {
      position: relative;
      bottom: 2px;
      font-size: 17px;
    }

    h3 {
      text-align: center;
      @media only screen and (min-width: $small-screen-up) {
        text-align: left;
        margin-left: 20px;
      }
    }

    p {
      font-size: 120%;
      max-width: 480px;
      margin: 10px auto 20px;
      @media only screen and (min-width: $small-screen-up) {
        margin: 10px 24px;
      }
      @media only screen and (max-width: $small-screen) {
        max-width: 100%;
      }
    }

    .flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media only screen and (max-width: $large-screen) {
        display: flex;
        flex-direction: column;
      }

      .box-a {
        max-width: 380px;
        @media only screen and (max-width: $large-screen) {
          order: 3;
          max-width: 420px;
        }
      }

      .box-b {
        img {
          max-width: 240px;
          width: 100%;
        }
        @media only screen and (max-width: $large-screen) {
          order: 2;
        }
      }

      .box-c {
        .btn-container {
          max-width: 480px;
          margin: 20px 0 14px 10px;
          display: flex;
          @media only screen and (min-width: $large-screen) {
            position: relative;
            top: 20px;
          }
          @media only screen and (max-width: $small-screen) {
            display: block;
            margin: 20px auto;
            top: 0;
          }

          .btn {
            width: 240px;
            padding: 0;
            margin: 0 10px 20px;
            @media only screen and (max-width: $small-screen) {
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    .section-container {
      max-width: 1280px;
      width: 75%;
      margin: 0 auto;
      @media only screen and (max-width: $large-screen) {
        width: 60%;
      }
      @media only screen and (max-width: $medium-screen) {
        width: 85%;
      }
      @media only screen and (max-width: $small-screen) {
        width: 90%;
      }
    }
  }
}
// UI PATTERN LIBRARY

#ui-pattern-library {
  .back-link {
    .material-icons {
      font-size: 22px;
      vertical-align: middle;
      position: relative;
      bottom: 2px;
    }
  }

  .sidenav-header {
    padding: 10px 20px;
    text-align: center;
    margin-bottom: 20px;

    .logo-image {
      max-width: 200px;
      margin-top: 20px;
    }

    h1 {
      font-size: 32px;
      font-weight: 600;
      margin: 10px 0 0;
    }
  }
  @media only screen and (max-width: 992px) {
    .tabs .tab {
      flex-grow: 0;
    }
  }

  .core-link-container {
    margin-top: 7px;
  }

  .core-link {
    padding-left: 20px;
  }

  .tabs {
    height: 337px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
    padding-bottom: 80px;

    .tab {
      text-align: left;

      a {
        padding-left: 40px;
        text-transform: none;
        font-size: 18px;
      }
    }
  }

  main {
    padding-left: 300px;
    padding-top: 40px;
    @media only screen and (max-width: $medium-screen) {
      padding-left: 0;
    }
  }

  .code-style-output {
    margin-left: 5px;
    background-color: #F7F7F9;
    color: #DC9096;
    border: 1px solid #E5E5EB;
    padding: 4px 8px;
    font-family: "Courier New", Courier, monospace;
    font-weight: 600;
  }

  #intro {
    .welcome-div {
      padding: 1px 20px;
      margin: 10px 0;
      border-radius: 4px;
    }

    p {
      font-size: 17px;
    }
  }

  #colors {
    hr {
      width: 70%;
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #ebeef2;
      margin: 1em auto;
      padding: 0;
    }

    .row {
      padding: 10px 0 30px;
    }

    .color-block {
      border-radius: 50px;
      width: 70px;
      height: 70px;
      margin: 30px auto 0;

      &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
        transform: scale(1.05,1.05);
        -webkit-transform: scale(1.05,1.05);
        -moz-transform: scale(1.05,1.05);
        transition: all linear 0.3s;
        cursor: pointer;
      }
    }

    h4 {
      padding-bottom: 7px;
      padding-top: 20px;
    }

    h6 {
      font-weight: 700;
      margin-bottom: 3px;
    }

    p {
      margin: 0;
      font-size: 14px;
    }

    .sass {
      color: #c69;
    }

    .hex {
      color: #999;
    }

    .materialize-class {
      padding-bottom: 20px;
    }

    .primary-green {
      background-color: $primary-green;
    }

    .primary-blue {
      background-color: $primary-blue;
    }

    .blue-dark {
      background-color: $blue-dark;
    }

    .blue-links {
      background-color: $blue-links;
    }

    .logo-light {
      background-color: $logo-light;
    }

    .logo-dark {
      background-color: $logo-dark;
    }

    .blue-nav {
      background-color: $blue-nav;
    }

    .footer-text {
      background-color: $footer-text;
    }

    .gold-light {
      background-color: $gold-light;
    }

    .gold-dark {
      background-color: $gold-dark;
    }

    .plum-light {
      background-color: $plum-light;
    }

    .plum-dark {
      background-color: $plum-dark;
    }

    .blue-cta {
      background-color: #039be5;
    }

    .blue-accent {
      background-color: #0280FF;
    }
    // Plan Colors
    .plan-silver {
      background-color: #C4CDD5;
    }

    .plan-silver-dark {
      background-color: #919EAA;
    }

    .plan-gold {
      background-color: #FFE9A0;
    }

    .plan-gold-dark {
      background-color: #D4AF37;
    }

    .plan-platinum {
      background-color: #9FBDD7;
    }

    .plan-platinum-dark {
      background-color: #253D53;
    }

    .plan-platinum-plus {
      background-color: #5DEDCC;
    }

    .plan-platinum-plus-dark {
      background-color: #00B188;
    }

    .plan-card-blue {
      background-color: #4d8fd6;
    }
    // Gears
    .gear-yellow {
      background-color: #F6A71C;
    }

    .gear-blue {
      background-color: #0066CC;
    }

    .gear-pink {
      background-color: #C445FF;
    }

    .gray-lightest {
      background-color: $gray-lightest;
    }

    .gray-lighter {
      background-color: $gray-lighter;
    }

    .gray-light {
      background-color: $gray-light;
    }

    .gray {
      background-color: $gray;
    }

    .gray-dark {
      background-color: $gray-dark;
    }

    .gray-darker {
      background-color: $gray-darker;
    }

    .gray-darkest {
      background-color: $gray-darkest;
    }

    .blue-light-bg {
      background-color: $blue-light-bg;
    }
    // Neons
    .neon-pink {
      background-color: #C445FF;
    }

    .neon-yellow {
      background-color: #F7EA06;
    }

    .neon-blue {
      background-color: #0066CC;
    }

    .neon-green {
      background-color: #06EFB2;
    }

    .neon-purple {
      background-color: #0033CC;
    }
  }
}
// Grid Tab Styles

.box {
  @include basic-box;

  &-item {
    @include basic-box-item;
  }
}
@media only screen and (max-width: $small-screen) {
  .responsive-example .row .col:last-child .box {
    margin: 0 0 20px;
  }
}

#contact-us {
  background-image: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/contact-us-background.jpg");
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    font-weight: 700;
    font-size: 240%;
    margin-top: 20px;
    @media only screen and (max-width: $small-screen) {
      font-size: 28px;
      margin: 20px 0 15px;
    }
  }

  .section-header {
    font-size: 240%;
    font-weight: 700;
    margin: 30px 0 0;
    @media only screen and (max-width: $small-screen) {
      font-size: 28px;
      margin: 10px 0;
    }
  }

  .section-subheader {
    font-size: 28px;
    font-weight: 300;
    margin: 0;
    line-height: 1.3;
    @media only screen and (max-width: $small-screen) {
      font-size: 21px;
      margin: 10px 0;
    }
  }

  .mod-messenger-icon {
    left: 10px;

    svg {
      margin-bottom: 11px;
    }
  }

  .mod-chat-icon {
    left: 7px;
    font-size: 66px;

    svg {
      margin-bottom: 11px;
      fill: $blue-cta;
    }
  }

  .messenger-icon {
    transform: scale(3.2,3.2);
  }

  .support-card-container-desktop {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: $ipad-max) {
      display: none;
    }
  }

  .support-card {
    width: 200px;
    height: 190px;
    margin: 0 20px 10px;
    text-align: center;
    background-color: $white;
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 12px 0 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
    color: $blue-dark;
    transition: all 0.8s ease-in-out;

    &:hover {
      background-color: $blue-cta;
      color: white;
      box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
      transform: scale(1.1,1.1);
      -webkit-transform: scale(1.1,1.1);
      -moz-transform: scale(1.1,1.1);
      transition: all 0.4s ease-in-out;
      border: 2px solid $blue-links;

      .messenger-icon {
        fill: $white;
      }

      .chat-icon {
        fill: $white;
      }

      .support-icon {
        color: $white;
      }
    }
    @media only screen and (max-width: $ipad-max) {
      width: 100%;
      height: 110px;
      padding: 15px 0 0;
    }

    p {
      margin: 0;
    }

    .support-icon {
      font-size: 480%;
      padding-bottom: 22px;
      color: $blue-cta;
    }

    .support-card-header {
      font-weight: 700;
      font-size: 170%;
    }

    .support-contact-small {
      font-size: 110%;
      font-weight: 400;
    }
  }

  .support-card-container-mobile {
    .support-card {
      margin: 0 0 10px;
    }
    @media only screen and (min-width: $ipad-min) {
      display: none;
    }
  }

  .max-width-540 {
    margin: 10px auto 60px;
    font-size: 17px;
    line-height: 1.5;
    @media only screen and (max-width: $small-screen) {
      margin-bottom: 80px;
    }
  }
}

#faqs {
  h1 {
    font-size: 32px;
    @media only screen and (max-width: $small-screen) {
      margin-top: 40px;
    }
  }

  h2 {
    font-weight: 400;
    font-size: 19px;
    margin: 15px 0 0;
    line-height: 1.3;
  }

  .faqs-container {
    max-width: 660px;
    width: 95%;
    margin: 0 auto;
    @media only screen and (max-width: $hvga-screen) {
      width: 100%;

      .row .col {
        padding: 0;
      }
    }
  }

  .disclaimer-text {
    text-align: left;
    padding: 12px 10px 0;
  }

  h3 {
    margin: 20px 0 14px;
    font-size: 26px;
  }

  .collapsible.popout > li {
    @media only screen and (max-width: $small-screen) {
      margin: 0;
    }
  }

  .collapsible-header {
    justify-content: space-between;
    font-size: 20px;
    line-height: 1.7;
    @media only screen and (max-width: $small-screen) {
      line-height: 1.4;
    }
  }

  .collapsible-body {
    background: white;
    font-size: 17px;
  }

  .collapsible i {
    transition: all 1s;
    line-height: 1.5;
    @media only screen and (max-width: $small-screen) {
      line-height: 1.4;
    }
  }

  .collapsible li.active i {
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -webkit-transform: rotate(180deg);
    /* Chrome, Safari, Opera */
    transform: rotate(180deg);
  }

  hr {
    background: #d2d2d2;
    border: 0 solid transparent;
    display: block;
    height: 1px;
    margin: 20px 0;
  }

  .level-details-container {
    .details-header {
      font-size: 24px;
      margin: 10px 0 4px;
      line-height: 1.2;
    }

    .details-content {
      margin-top: 0;
    }
  }

  .plus-ball {
    background-image: linear-gradient(-90deg, $plan-platinum-plus, $plan-platinum-plus-dark);
    color: #FFFFFF;
    font-weight: bold;
    font-size: 24px;
    line-height: .9;
    width: 20px;
    height: 20px;
    border-radius: 99px;
    margin: 0 7px 0 3px;
    display: inline-block;
    text-align: center;
  }
}

#account-content {
  padding-top: 20px;

  .edit-icon {
    font-size: 17px;
    position: relative;
    top: 3px;
  }

  .site-container {
    background-color: #f1f4f8;
    padding: 10px;

    .row {
      margin-left: auto;
      margin-right: auto;
    }

    h4 {
      font-size: 34px;
      color: $blue-nav;
      font-weight: 400;
      line-height: 1.1;
      margin: 10px 0;
    }

    h5 {
      color: $blue-nav;
      font-size: 20px;
    }

    h6 {
      color: $blue-nav;
    }

    .material-icons {
      vertical-align: inherit;
    }

    .account-details {
      .card-body {
        padding: 10px;
      }
    }

    .payment,
    .vehicle-info {
      p {
        color: rgba(0,0,0,.87);
      }

      strong {
        color: rgba(0,0,0,.87);
      }

      a {
        margin: 5px 0;
        width: 100%;

        &.payment-button {
          background-color: rgb(0, 53, 90);
          color: #fff;
          margin: 0;
          width: auto;
        }
      }

      .payment-history-cta {
        margin-top: 10px;
        display: inline-block;
      }

      .link {
        padding-left: 30px;
      }

      .editor-view {
        background-color: #f1f4f8;

        .edit {
          padding: 30px;
        }
      }

      .btn-outline {
        &.mod-blue-nav {
          border: 1px solid $blue-nav;
          color: $blue-nav;
        }
      }

      .btn-flat {
        border-radius: 99px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1px;
        padding: 0 30px;
        line-height: 45px;
        height: 45px;
        transition: all 0.2s linear;

        &:hover {
          color: #fff;
          transform: scale(1,1);
          -webkit-transform: scale(1,1);
          -moz-transform: scale(1,1);
        }
      }
    }

    .hidden-attributes {
      display: none;
    }
  }
}

.account-modal {
  h3 {
    margin: 20px 0 10px;
  }

  .modal-close {
    display: block;
    margin-top: 14px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.2px;

    &.close-x {
      position: fixed;
      top: 0;
      right: 14px;
      border: 2px solid #777;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      line-height: 1.8;
      text-align: center;
      font-weight: 300;
      font-size: 21px;
      transition: 0.5s ease-in-out;
      color: #777;

      &:hover {
        color: white;
        background-color: #777;
        font-weight: 700;
      }
    }
  }

  &.modal-slim {
    max-width: 480px;
  }
}

.modal.open {
  outline: none;
}

#account-page-single-event {
  max-width: 480px;
  margin: 0 auto;
  padding: 0 20px;
}

#about-us {
  #our-vision {
    height: 400px;

    .section-header {
      padding-top: 80px;
      font-size: 32px;
      margin-bottom: 5px;
    }

    .section-subheader {
      font-size: 21px;
      font-weight: 300;
      max-width: 380px;
      margin: 0 auto;
      line-height: 1.4;
    }

    .fullscreen-bg {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      z-index: -100;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0,0,0,.3);
        z-index: 1;
      }
    }

    .fullscreen-bg-video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    @media (min-aspect-ratio: 16/9) {
      .fullscreen-bg__video {
        height: 300%;
        top: -100%;
      }
    }
    @media (max-aspect-ratio: 16/9) {
      .fullscreen-bg__video {
        width: 300%;
        left: -100%;
        height: 100%;
      }
    }
    @media (max-width: 767px) {
      .fullscreen-bg {
        background: url("https://cdn2.hubspot.net/hubfs/2592532/B2B%20Microsite/Home-Mobile-Hero2.jpg") center center / cover no-repeat;
      }

      .fullscreen-bg-video {
        display: none;
      }
    }
  }

  #our-mission {
    .container {
      max-width: 600px;
    }

    .section-subheader {
      font-size: 21px;
      line-height: 1.3;
    }
  }

  #every-road {
    background-image: linear-gradient(rgba($blue-darkest, 0.7), rgba($blue-darkest, 0.7)), url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/office2.jpg");
    background-size: cover;
    background-position: bottom right;

    .section-header {
      font-weight: 600;
    }

    .section-subheader {
      font-size: 21px;
      line-height: 1.3;
    }
  }

  #fc-timeline {
    background: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/ROAD.jpg") no-repeat center top;
    position: relative;
    height: 1400px;
    @media only screen and (max-width: $ipad-max) {
      display: none;
    }

    .leftside {
      position: absolute;
      left: 35%;
      text-align: right;
      @media only screen and (max-width: 2400px) {
        left: 33%;
      }
      @media only screen and (max-width: 2000px) {
        left: 30%;
      }
      @media only screen and (max-width: 1760px) {
        left: 26%;
      }
      @media only screen and (max-width: $extra-large-screen) {
        left: 22%;
      }
      @media only screen and (max-width: $large-screen) {
        left: 15%;
      }
      @media only screen and (max-width: $medium-screen) {
        left: 8%;
      }
    }

    .rightside {
      position: absolute;
      right: 35%;
      @media only screen and (max-width: 2400px) {
        right: 33%;
      }
      @media only screen and (max-width: 2000px) {
        right: 30%;
      }
      @media only screen and (max-width: 1760px) {
        right: 26%;
      }
      @media only screen and (max-width: $extra-large-screen) {
        right: 22%;
      }
      @media only screen and (max-width: $large-screen) {
        right: 15%;
      }
      @media only screen and (max-width: $medium-screen) {
        right: 8%;
      }
    }

    .fc-logo-container {
      position: absolute;
      width: 200px;
      top: 110px;
      margin-left: 60px;
    }

    .fc-logo {
      max-width: 220px;
    }

    .year {
      p {
        max-width: 250px;
      }
    }

    .year-header {
      font-size: 28px;
      color: $blue-links;
      margin: 0;
      line-height: 1;
    }

    .y2011-13 {
      top: 80px;
    }

    .y2014 {
      top: 220px;
    }

    .y2015 {
      top: 350px;
    }

    .y2016 {
      top: 400px;
    }

    .y2017 {
      top: 570px;
    }

    .y2018 {
      top: 960px;
    }

    .move-forward {
      top: 1060px;
    }
  }

  #fc-timeline-mobile {
    background: url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/ROAD.jpg") no-repeat;
    background-position: -50px 0;
    padding: 50px 0;
    @media only screen and (min-width: $ipad-min) {
      display: none;
    }

    .container {
      max-width: 300px;
      padding-left: 60px;
    }

    .fc-logo-container {
      width: 200px;
      margin-bottom: 50px;
    }

    .fc-logo {
      max-width: 220px;
    }

    .year {
      p {
        max-width: 250px;
      }
    }

    .year-header {
      font-size: 28px;
      color: $blue-links;
      margin: 0;
      line-height: 1;
    }
  }
}

#our-team {
  h3 {
    margin: 0;
    font-size: 26px;
  }

  .container {
    max-width: 800px;
  }

  .div-header {
    background-color: $gray-lightest;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      color: $blue-darkest;
      text-align: center;
      font-size: 17px;
      font-weight: 700;
      letter-spacing: 1.7px;
    }
  }

  #hero {
    background: linear-gradient(rgba($blue-cta, 0.7), rgba($blue-cta, 0.7)), url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/office1.jpg") no-repeat;
    background-position: 0 20%;
    height: 260px;
    background-size: cover;

    h1 {
      padding-top: 90px;
      font-size: 42px;
      font-weight: 300;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
    }
  }

  #leadership {
    .name {
      color: $blue-links;
    }

    .title {
      margin: 0;
      font-weight: 400;
      color: $blue-darkest;
    }

    .headshot {
      max-width: 320px;
      width: 100%;
      margin: 20px 0 5px;
    }
  }

  #team {
    .headshot {
      max-width: 320px;
      width: 100%;
      margin: 20px 0 5px;
    }

    .name {
      margin: 0;
      font-weight: 600;
      color: $blue-darkest;
    }

    .title {
      margin: 0;
    }

    .row {
      @media only screen and (max-width: $small-screen) {
        text-align: center;
      }
    }
  }

  #protectionators {
    .name {
      color: $blue-links;
    }

    .phone {
      margin: 5px 0 10px;
    }
  }
}

#page-404 {
  .chipmunk {
    max-width: 720px;
    width: 100%;
  }

  h1 {
    margin: 20px 0 0;
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }
}

#careers {
  #hero {
    background: linear-gradient(rgba($blue-cta, 0.7), rgba($blue-cta, 0.7)), url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/careers-hero-bg.jpg");
    background-position: center 60%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: 400px;

    .section-padding {
      padding: 100px 0;
    }

    h1 {
      font-size: 32px;
      margin: 30px 0 0;
      text-shadow: 1px 1px 10px rgba(0,0,0,0.3);
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
      text-shadow: 1px 1px 10px rgba(0,0,0,0.3);
    }

    .btn {
      text-shadow: 1px 1px 10px rgba(0,0,0,0.3);
      box-shadow: 1px 1px 10px rgba(0,0,0,0.3);

      &:hover {
        text-shadow: none;
      }
    }
  }

  #who-we-are {
    .section-padding {
      padding: 45px 0 15px;
    }

    .section-header {
      font-size: 32px;
      color: $blue-darkest;
    }

    .section-subheader {
      color: $blue-darkest;
    }
  }

  #what-we-stand-for {
    background: linear-gradient(rgba(145, 92, 14, 0.8), rgba(145, 92, 14, 0.8)), url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/careers-what-we-stand-for-bg.jpg");
    background-size: cover;
    background-position: center 50px;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .section-padding {
      padding: 120px 0 90px;
    }

    .section-header {
      font-weight: 700;
      font-size: 32px;
      margin-bottom: 40px;
      text-shadow: 1px 1px 10px rgba(0,0,0,0.3);
    }

    .icons-container {
      max-width: 600px;
      margin: 0 auto;

      .fa-magic {
        transform: scale(1.8,1.8);
        filter: drop-shadow(1px 1px 10px rgba(0,0,0,0.3));
      }

      .fa-search {
        transform: scale(1.7,1.7);
        filter: drop-shadow(1px 1px 10px rgba(0,0,0,0.3));
      }

      .fa-lightbulb {
        transform: scale(1.9,1.9);
        filter: drop-shadow(1px 1px 10px rgba(0,0,0,0.3));
      }

      .fa-heart-circle {
        transform: scale(2.1,2.1);
        filter: drop-shadow(1px 1px 10px rgba(0,0,0,0.3));
      }
    }

    .icon-header {
      font-weight: 600;
      font-size: 18px;
      text-shadow: 1px 1px 10px rgba(0,0,0,0.3);
    }
  }

  #where-we-thrive {
    .section-padding {
      padding: 45px 0 15px;
    }

    .section-header {
      font-size: 32px;
      color: $blue-darkest;
    }

    .section-subheader {
      color: $blue-darkest;
    }

    .btn-link {
      color: $blue-darker;
      letter-spacing: 1px;
      transition: all 0.8s ease-in-out;

      &:hover {
        color: $blue-links;
        transition: all 0.4s ease-in-out;
        transform: scale(1.1,1.1);
        display: inline-block;
      }
    }
  }

  #benefits {
    background: linear-gradient(rgba($plum-dark, 0.8), rgba($plum-dark, 0.8)), url("https://cdn2.hubspot.net/hubfs/2592532/ab/images/careers-benefits-bg.jpg");
    background-size: cover;
    background-position: center 50px;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .section-padding {
      padding: 120px 0 90px;
    }

    .section-header {
      font-weight: 700;
      font-size: 32px;
      margin-bottom: 40px;
      text-shadow: 1px 1px 10px rgba(0,0,0,0.3);
    }

    .icons-container {
      max-width: 400px;
      margin: 0 auto;

      .fa-location {
        transform: scale(1.8,1.8);
        filter: drop-shadow(1px 1px 10px rgba(0,0,0,0.3));
        text-shadow: 1px 1px 10px rgba(0,0,0,0.3);
      }

      .fa-money-bill-wave {
        transform: scale(1.7,1.7);
        filter: drop-shadow(1px 1px 10px rgba(0,0,0,0.3));
        text-shadow: 1px 1px 10px rgba(0,0,0,0.3);
      }

      .fa-heart-circle {
        transform: scale(2.1,2.1);
        filter: drop-shadow(1px 1px 10px rgba(0,0,0,0.3));
        text-shadow: 1px 1px 10px rgba(0,0,0,0.3);
      }

      .fa-balance-scale {
        transform: scale(1.9,1.9);
        filter: drop-shadow(1px 1px 10px rgba(0,0,0,0.3));
        text-shadow: 1px 1px 10px rgba(0,0,0,0.3);
      }
    }

    .icon-header {
      font-weight: 600;
      font-size: 18px;
      text-shadow: 1px 1px 10px rgba(0,0,0,0.3);
    }
  }

  #openings {
    .section-subheader {
      font-size: 24px;
      line-height: 1.2;
    }

    a {
      font-weight: 600;
    }
  }
}
