#account-page-single-event {
  .event-block {
      padding: 40px 10px;

      button {
        margin-bottom: 10px;
      }

      input {
        padding: 5px 5px;
      }
  }
}
