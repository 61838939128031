
::placeholder {

}


.form-wrapper {
  padding: 16px 0;
}

h2.ticket-title {
  text-align:center;
  font-size:24px;
  font-weight:500;
  line-height:2.0;
}


.buffer {
  padding: 18px;
}

.bg-buffer {
  padding: 15px !important;
}

.margin-fix{
  margin: 20px 10px 10px;
}

.left-margin-fix {
  margin-left: 10px;
}


.input-wrapper {
  padding: 16px 32px 0px;
}

// Affetcs checkbox labels
label {
  color: $blue-dark;
  font-weight: 400;
}

input#date, input#position,
input#manager, input#employee,
input#tasks, input#system,
input#hr, input#IT, input#ceo, input#coo {
  margin-bottom:3px;
}

.invalid {
  @extend %custom-error-message;
}

.select-wrapper {
  &.valid {
    & > input.select-dropdown {
      @extend %valid-input-style;
    }

    & + label:after {
      @extend %custom-success-message;
    }
  }

  &.invalid {
    & > input.select-dropdown {
      @extend %invalid-input-style;
    }

    & + label:after {
      @extend %custom-error-message;
    }
  }

  &.valid + label,
  &.invalid + label {
    width: 100%;
    pointer-events: none;
  }

  & + label:after {
    @extend %input-after-style;
  }

  position: relative;

  input.select-dropdown {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: $input-border;
    outline: none;
    height: $input-height;
    line-height: $input-height;
    font-size: $input-font-size;
    margin: $input-margin;
    padding: 0;
    display: block;
    user-select:none;
  }

  span.caret {
    color: initial;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 10px;
    margin: auto 0;
    font-size: 10px;
    line-height: 10px;
  }

  & + label {
    position: absolute;
    top: -26px;
    font-size: $label-font-size;
  }
}

// Disabled styles
select:disabled {
  color: $input-disabled-color;
}

.select-wrapper.disabled {
  span.caret,
  & + label {
    color: $input-disabled-color;
  }
}

.select-wrapper input.select-dropdown:disabled {
  color: $input-disabled-color;
  cursor: default;
  user-select: none;
}

.select-wrapper i {
  color: $select-disabled-color;
}

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
  color: $select-disabled-color;
  background-color: transparent;
}

.select-dropdown.dropdown-content {
  li {
    &.active {
      background-color: transparent;
    }

    &:hover {
      background-color: $select-option-hover;
    }

    &.selected {
      background-color: $select-option-focus;
    }
  }
}

.dropdown-content {
  overflow-y: scroll;
}

.input-field.light-field input:not([type]),
.input-field.light-field input[type=text]:not(.browser-default),
.input-field.light-field input[type=password]:not(.browser-default),
.input-field.light-field input[type=email]:not(.browser-default),
.input-field.light-field input[type=url]:not(.browser-default),
.input-field.light-field input[type=time]:not(.browser-default),
.input-field.light-field input[type=date]:not(.browser-default),
.input-field.light-field input[type=datetime]:not(.browser-default),
.input-field.light-field input[type=datetime-local]:not(.browser-default),
.input-field.light-field input[type=tel]:not(.browser-default),
.input-field.light-field input[type=number]:not(.browser-default),
.input-field.light-field input[type=search]:not(.browser-default),
.input-field.light-field textarea.light-field.materialize-textarea {

  // General Styles
  border-color: $white;
  color: $white;

  // Disabled input style
  &:disabled,
  &[readonly="readonly"] {
    border: $gray-lightest;
    color: $gray-lightest;
  }

  // Disabled label style
  &:disabled+label,
  &[readonly="readonly"]+label {
    color: $gray-lightest;
  }

  // Focused input style
  &:focus:not([readonly]) {
  }

  // Focused label style
  &:focus:not([readonly])+label {
    color: $white;
  }

}

.input-field input[type=datetime-local]:not(.browser-default) {
  +label {
    transform: translateY(-14px) scale(.8);
    transform-origin: 0 0;
  }
}

// Input styles
input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {

  // General Styles
  font-size: 15px;
  font-weight: 400;
  color: $blue-dark;

  // Disabled input style
  &:disabled,
  &[readonly="readonly"] {
    border: $gray-light;
    color: $gray-light;
  }

  // Disabled label style
  &:disabled+label,
  &[readonly="readonly"]+label {
    color: $gray-light;
  }

  // Focused input style
  &:focus:not([readonly]) {
    border-color: $white;
  }

  // Focused label style
  &:focus:not([readonly])+label {
    color: $primary-green;
  }

  // Valid Input Style
  &.valid,
  &:focus.valid {
    border-bottom: 1px solid $gray-dark;
    box-shadow: 0 1px 0 0 $gray-dark;
  }

  &.valid + label,
  &:focus.valid + label {
    color: $primary-green;
  }

  // Custom Success Message
  &.valid + label:after,
  &:focus.valid + label:after {
    @extend %custom-success-message;
  }

  // Invalid Input Style
  &.invalid,
  &:focus.invalid {
    @extend %invalid-input-style;
  }

  // Custom Error message
  &.invalid + label:after,
  &:focus.invalid + label:after {
    @extend %custom-error-message;
  }

  // Full width label when using validate for error messages
  &.validate + label {
    width: 100%;
  }

  // Form Message Shared Styles
  & + label:after {
    @extend %input-after-style;
  }

  // TODO: Remove once input fields are reworked to support validation messages better
  &.invalid + label:after,
  &.valid + label:after{
    display: none;
  }

  &.invalid + label.active:after,
  &.valid + label.active:after{
    display: block;
  }
}


/* Validation Sass Placeholders */
%valid-input-style {
  border-bottom: 1px solid $input-success-color;
  box-shadow: 0 1px 0 0 $input-success-color;
}
%invalid-input-style {
  border-bottom: $input-invalid-border;
  box-shadow: 0 1px 0 0 $input-error-color;
}
%custom-success-message {
  content: attr(data-success);
  color: $input-success-color;
  opacity: 1;
}
%custom-error-message {
  content: attr(data-error);
  color: $input-error-color;
  opacity: 1;
}
%input-after-style {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: .1s opacity ease-out, .1s color ease-out;
}

.input-field .select-wrapper .caret {
  z-index: 1;
}

.input-field label {
  font-size: 15px;
  font-weight: 600;
  transition: transform .1s ease-out;
  transform-origin: 0% 100%;
  text-align: initial;
  pointer-events: none;
  color: $blue-dark;
  &:not(.label-icon).active {
    transform-origin: 0 0;
  }
}

.input-field.light-field label {
  color: $white;
}

.radio-field {
  margin: 0 20px 0 0;
}

.radio-field label.field-wrapper {
  // General Styles
  background: $white;
  border: 1px $gray-lighter solid;
  border-radius: $border-box-radius;
  box-shadow: $box-shadow;
  box-sizing: content-box;
  font-size: 15px;
  font-weight: 600;
  color: $blue-dark;
  height: auto;
  outline: 0;
  padding: 25px 0 25px 20px;
  display: block;
  &:hover {
    cursor: pointer;
  }
}

.invalid-field {
  position: absolute;
  padding-left: 10px !important;
  color: red !important;
  font-size: 14px !important;

  &-relative {
    padding-left: 10px !important;
    color: red !important;
    font-size: 14px !important;
    position: relative;
  }
}

.invalid-inline-field {
  font-size: 12px;
  margin-left: 7.0em;
  color: red !important;
  position: relative;

  @media only screen and (max-width: 375px) {
    font-size: 11px;
    margin-left: 4.0em;
  }

  @media only screen and (max-width: 320px) {
    font-size: 11px;
    margin-left: 3.5em;
  }
}

// RANGE SLIDERS
.noUi-connect {
  background: $messenger-blue; /* Old browsers */
  background: -moz-linear-gradient(45deg, $messenger-blue 0%, $green-light 100%);
  background: -webkit-linear-gradient(45deg, $messenger-blue 0%, $green-light 100%);
  background: linear-gradient(45deg, $messenger-blue 0%, $green-light 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8', GradientType=1 );
}



[type="checkbox"] + span:not(.lever) {
  &.checkbox-text {
    font-size: 1.6rem;
    color: #425363;
    font-weight: 600;
  }
}
