th {
  font-size: 17px;
  font-weight: 700;
}

td {
  font-size: 17px;
}

dd {
  -webkit-margin-start: 0;
  margin: 0;
}

dt {
  margin-top: 20px;
}

.table-wide-container {

  width: 100%;
  overflow-y: auto;
  margin: 0 0 1em;

  table {

    th {
      color: $white;
      background-color: $blue-darkest;
      border-radius: 0px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;

      @media screen and (min-width: $medium-screen) {
        padding: 10px 20px 5px 10px;
      }

    }

    td {
      font-size: 16px;
      line-height: 1.4;
    }

    .td-center {
      padding-left: 30px;
    }
  }


}

.vehicle-container {
  margin-bottom: 20px;

  table {

    tbody {
      border: 1px solid lighten($blue-dark, 50%);

      .active-contract {
        background-color: $primary-green;

        td {
          padding: 0px;
        }

        h6 {
          color: $white;
          margin: 0px;
        }
      }
    }


    th {
      padding-left: 20px;
      border-radius: 0px;
    }

    thead {
      tr:first-of-type {
        background-color: $blue-dark;

        th {
          border-top-left-radius: 10px;
        }

        td {
          border-top-right-radius: 10px;
        }

        h4, h6 {
          color: $white;
        }

        h6 {
          margin: 0px;
        }

        a {
          padding-right: 10px;
          color: $white;

          &:hover {
            color: darken($white, 20%);
          }
        }

      }
    }


  }


}



.table-data-container {
  width: 100%;
  overflow-y: auto;
  margin: 0 0 1em;

  table.dataTable.no-footer {
    border-bottom: 1px solid #ccc;
  }

  table {
    border: 1px solid #ccc;
  }

  .dataTables_info {
    color: $blue-dark;
  }

  label {
    font-size: 15px;
    font-weight: 600;
  }

  .dataTables_filter {
    label {
      font-size: 15px;
      font-weight: 600;
      display: flex;

      input {
        height: 20px;
        margin-left: 10px;
      }
    }
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button {
    color: $blue-dark !important;
    outline: none;
  }

  .next, .previous {
    font-weight: 600;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
    color: $white !important;
    border: 1px solid transparent;
    background: $blue-links;
    font-weight: 600;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    border: 1px solid $blue-links;
    color: $blue-links !important;
    background: $white;
    font-weight: 600;
    border-radius: 2px;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
    border: 1px solid transparent;
    background: transparent;
    outline: none;
    font-weight: 400;
    color: #999 !important;
  }

  .dataTables_length {
    label {
      display: flex;
    }

    select {
      display: inline-block;
      height: 1.6rem;
      margin: 0 5px;
      color: $blue-dark;
    }
  }

  td {
    font-size: 15px;
  }

  th {
    font-weight: 600 !important;
    font-size: 15px;
    border-bottom: 1px solid #999 !important;
    border-radius: 0px;
  }


}
