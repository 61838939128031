a {
  cursor: pointer;
  color: $blue-links;

  &:hover {
    transition: all linear 0.3s;
  }
}

.btn-container {
  margin: 2rem 0 3rem 0;
}

.external-link {
  text-transform: uppercase;
  font-size: 130%;
  letter-spacing: 0.4px;
  font-weight: 700;
}

.link-blue {
  cursor: pointer;
  color: $blue-links;
  font-weight: 700;
  transition: all 1s;

  &:hover {
    transition: all 0.5s;
    color: darken($blue-cta, 20%);
  }
}

.link-blue-grow {
  cursor: pointer;
  color: $blue-links;
  font-weight: 700;
  transition: all 1s;
  display: inline-block;

  &:hover {
    transition: all 0.5s;
    color: darken($blue-cta, 20%);
    transform: scale(1.05, 1.05);
    background-color: $white;
    padding: 3px;
  }
}

.link-white {
  cursor: pointer;
  color: $white;
  font-weight: 700;

  &:hover {
    transition: all linear 0.3s;
    color: darken($blue-links, 20%);
  }
}

.link-arrow {
	display: inline-block;
    height: 1rem;
    line-height: 1rem;

	.arrow-icon {
		position: relative;
		top: -1px;
		-webkit-transition: -webkit-transform 0.3s ease;
		transition: -webkit-transform 0.3s ease;
		transition: transform 0.3s ease;
		transition: transform 0.3s ease, -webkit-transform 0.3s ease;
		vertical-align: middle;
	}

	.arrow-icon--circle {
		transition: stroke-dashoffset .3s ease;
		stroke-dasharray: 95;
		stroke-dashoffset: 95;
	}

	&:hover {
		.arrow-icon {
			transform: translate3d(5px, 0, 0);
		}

		.arrow-icon--circle {
			stroke-dashoffset: 0;
		}
	}
}

.link-underline-slide {
    transition:all 1s linear;
		display:inline;
		padding-bottom:5px;
		background: linear-gradient(to right, $blue-links 0%,$blue-cta 98%);
		background-size:0px 2px;
		background-repeat: no-repeat;
		background-position:left 85%;
		text-shadow:0;

		&:hover{
			background-size:100% 2px;
		}
}

.link-underline-slide-blue-links {
    transition:all 1s linear;
		display:inline;
		padding-bottom:5px;
		background: linear-gradient(to right, $blue-links 0%,$blue-links 98%);
		background-size:0px 2px;
		background-repeat: no-repeat;
		background-position:left 85%;
		text-shadow:0;

		&:hover{
			background-size:100% 2px;
		}
}

	// grow hover effect - grows outwards from middle
	.link-grow {
    position: relative;

    &:after {
      content: ' ';
      position: absolute;
      top: 100%;
      left: 25%;

      width: 50%;
      height: 3px;
      border-bottom: 3px solid $blue-links;

      transition: 0.4s;
    }

    &:hover {
      &:after {
        width: 100%;
    		left: 0;
      }
    }
  }

	// highlight hover effect - bottom-to-top
	.link-highlight {
    position: relative;

    &:after {
      content: ' ';
      position: absolute;
      top: 100%;
      left: -2px;

      padding: 0 2px;
      width: calc(100% + 2px);
      height: 1px;
      border-bottom: 3px solid $blue-links;

      transition: 0.25s;
    }

    &:hover {
      &:after {
        top: 0;
    		height: 1.5em;
    		background-color: rgba($blue-links, 0.5);
    		border-color: transparent;
      }
    }
  }

	// swipe hover effect - left-to-right
	.link-swipe {
    position: relative;

    &:before {
      content: ' ';
      position: absolute;
      top: 100%;
      left: -2px;

      padding: 0 2px;
      width: calc(100% + 2px);
      height: 1px;
      border-bottom: 3px solid $blue-links;

      transition: 0.4s;
    }

    &:after {
  		content: ' ';
  		position: absolute;
  		top: 0;
  		left: -2px;

  		padding: 0 2px;
  		width: 1px;
  		height: 1.5em;

  		transition: 0.4s;
  	}

    &:hover {
      &:after {
        width: 100%;
    		background-color: rgba($blue-links, 0.75);
      }
    }
  }

	// magic button hover effect - appears and scales from bottom
	.magic-btn {
    display: inline-block;
    transition: 0.3s;
    transition-delay: 0s;
    transform: scale(1);

    &:before {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: -2px;
      padding: 0 2px;

      width: calc(100% + 2px);
      height: 1px;
      border-bottom: 3px solid $blue-links;

      transition: 0.4s;
    }

    &:after {
      content: ' ';
  		position: absolute;
  		z-index: -1;
  		bottom: 0;
  		left: calc(50% - 4px);
  		padding: 0 2px;

  		width: 0;
  		height: 0;

  		border: 1px solid transparent;

  		transition: 0.4s;
    }

    &:hover {
      color: #fff;
      transform: scale(1.4);
      transition-delay: 0.11s;

      &:before {
        border-color: transparent;
      }

      &:after {
        width: 100%;
        height: 1.25em;
        left: -1em;
        bottom: -0.35em;
        padding: 0.5em 1em;
        border-color: $blue-links;
        background-color: $blue-links;
        border-radius: 2.5em;
      }
    }
  }

.btn {
  line-height: 45px;
  height: 45px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-link {
  color: $blue-links;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  transition: all ease-in-out 0.2s;

  &.blue-cta {
    color: $blue-cta;
  }

  &:hover {
    color: darken($blue-links, 15%);
    cursor: pointer;
    transition: all ease-in-out 0.4s;
  }
}

.silver-link {
  color: $plan-silver-dark;
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn-right-fix{
  float:right;
  margin-top:-23px;
}

.btn-fccx {
  border-radius: $border-btn-radius !important;
  font-size: 1.35rem;
  letter-spacing: 1.2px;
  height: 40px;
  line-height: 1.2;
  padding: 12px 42px;
  transition: all linear 0.5s;
  font-weight: 700;
  box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.2);
  text-transform: uppercase;
  font-weight: 700;
  outline: none;
  margin: 4px 0;

  &:hover {
    box-shadow: 1px 3px 4px 1px rgba(0,0,0,0.1);
    transition-property: background-color, box-shadow;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
  }
  @media only screen and (max-width: $small-screen) {
    font-size: 1.35rem;
  }

  &.disabled {
    background: $gray !important;
    color: $white !important;
  }
}

.btn-flat {
  border-radius: $border-btn-radius;
  font-size: 13.5px;
  letter-spacing: 0.8px;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: all linear 0.5s;
  font-weight: 700;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
    transform: scale(1.05,1.05);
    -webkit-transform: scale(1.05,1.05);
    -moz-transform: scale(1.05,1.05);
    transition: all linear 0.5s;
  }
  @media only screen and (max-width: $small-screen) {
    font-size: 12.5px;
  }

  &.disabled {
    background: $gray !important;
    color: $white !important;
  }
}



.btn-primary {
  background: $blue-nav;
  color: $white;

  &:active,
  &:focus,
  &:hover {
    background: darken($blue-nav, 5%);
  }
}

.btn-green {
  background: $primary-green !important;
  color: $white !important;

  &:active,
  &:focus,
  &:hover {
    background: darken($primary-green, 5%) !important;
  }
}

.btn-white-green {
  background: $white !important;
  color: $primary-green !important;
  border: 1px solid $primary-green !important;

  &:hover {
    background: $primary-green !important;
    color: $white !important;
  }
}

.btn-blue {
  background: #039be5;
  color: $white;

  &:active,
  &:focus,
  &:hover {
    background: darken(#039be5, 5%);
  }
}

.btn-white-blue {
  background: $white;
  color: $blue-nav;
  border: 1px solid $blue-nav;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;

  i, svg {
    font-size: 14px;
    color: $blue-cta;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }

  &:hover {
    background: $blue-cta;
    color: $white;
    border: 1px solid $blue-cta;

    i, svg {
      color: $white
    }
  }
}

.btn-gold {
  background: $gold-light;
  color: $white;

  &:active,
  &:focus,
  &:hover {
    background: darken($gold-light, 5%);
  }
}

.btn-alert {
  background: $alert-red;
  color: $white;

  &:active,
  &:focus,
  &:hover {
    background: darken($alert-red, 5%);
  }
}

.btn-outline {
  background: $white !important;
  border: 1px $gray-light solid;
  color: $gray-dark;

  &:hover {
    background: $gray-dark;
    border-color: $gray-dark;
    box-shadow: 0 0 0;
    color: $white;
  }
}

.btn-outline-white {
  background: transparent;
  border: 1px $white solid;
  color: $white;

  &:hover {
    background: $white;
    color: $grey-text;
  }
}

.btn-outline-green {
  border: 1px $primary-green solid;
  color: $primary-green;

  &:hover {
    background: $primary-green;
    border-color: $primary-green;
    color: $white;
  }
}

.btn-outline-blue {
  border: 1px $blue-cta solid;
  color: $blue-cta;

  &:hover {
    background: $blue-cta;
    border-color: $blue-cta;
    color: $white;
  }
}

.btn-outline.mod-blue-nav {
  border: 1px $blue-nav solid;
  color: $blue-nav;

  &:hover {
    background: $blue-nav;
    border-color: $blue-nav;
    color: $white;
  }
}

.btn-outline.mod-messenger {
  border-color: $messenger-blue;
  color: $messenger-blue;

  &:hover {
    background: $messenger-blue;
    border-color: $messenger-blue;
    color: $white;
  }
}

.main-cta-item.mod-messenger .mod-messenger-icon {
  position: relative;
  right: 5px;
  top: 7px;
  width: 25px;
}

.btn-white {
  background: $white;
  border: 1px $gray-lighter solid;
  border-radius: $border-btn-radius;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .1);
  color: $blue-nav;
  padding: 0 25px;

  &:active,
  &:focus,
  &:hover {
    background: $white;
    border-color: $gray-light;
    box-shadow: 0 4px 14px rgba(0, 0, 0, .1);
  }
}

.btn-flat.disabled,
.btn.disabled {
  background: $gray-lightest !important;
  color: $white !important;
}

/* Sweep To Top */
.hvr-sweep-to-top-blue-cta {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.hvr-sweep-to-top-blue-cta:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $blue-cta;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

  .hvr-sweep-to-top-blue-cta:hover, .hvr-sweep-to-top-blue-cta:focus, .hvr-sweep-to-top-blue-cta:active {
    color: white;
  }

  .hvr-sweep-to-top-blue-cta:hover:before, .hvr-sweep-to-top-blue-cta:focus:before, .hvr-sweep-to-top-blue-cta:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
