a {
  text-decoration: none;
}

html, .application {

    font-family: 'proxima-nova', helvetica, arial, sans-serif;
    font-weight: normal;
    line-height: 1.5;
    color: $blue-dark;
    -webkit-font-smoothing: antialiased;

  @media only screen and (min-width: 0) {
    font-size: 14px;
  }

  @media only screen and (min-width: $medium-screen) {
    font-size: 14.5px;
  }

  @media only screen and (min-width: $large-screen) {
    font-size: 15px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $blue-dark;
  font-weight: 400;
  line-height: 1.1;
  margin: 10px 0;
}

h1 {
  font-size: 48px;
  font-weight: 700;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 36px;
  font-weight: 300;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 21px;
}

h6 {
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: $medium-screen) {
  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 21px;
  }

  h6 {
    font-size: 17px;
  }
}

@media only screen and (max-width: $small-screen) {
  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 21px;
  }

  h6 {
    font-size: 17px;
  }
}

strong {
  font-weight: 600;
}

// CUSTOM FC CLASSES

.muted-text {
  color: $gray-dark;
}

.medium-weight {
  font-weight:400;
}

.font-style-italic {
  font-style: italic;
}

// FONT WEIGHTS

.font-weight-thin {
  font-weight: 100;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}
