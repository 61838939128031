// Brand Colors
$logo-light: #95a7cb;
$logo-dark: #002a48;
$blue-nav: #004876;

// Blues
$blue-links: #6986b7;
$blue-light-bg: #f1f4f8;
$blue-dark: #425363;
$grey-text: $blue-dark;
$blue-grey: rgb(67, 84, 99);

$blue-darker: #003f6b;
$blue-darkest: #002b49;

$blue-cta: #039be5;
$primary-blue: #0b79bf;
$blue-accent: #0280FF;

// Yellows
$gold-light: #f6a71c;
$gold-dark: #d57e00;
$yellow: #ffc524;

// Plums
$plum-light: #978693;
$plum-dark: #632c4f;

// Greens
$primary-green: #00b188;
$green-light: #6dcdb1;

// Text
$body-text: #32414E;
$footer-text: #9ccff3;

// Gray Scale
$gray-lightest: #ebeef2;
$gray-lighter: #d2d2d2;
$gray-light: #a7a9ac;
$gray: #eee;
$gray-dark: #939393;
$gray-darker: #5e5e5e;
$gray-darkest: #333;
$gray-bg: #f5f5f5;

// Alerts
$success-green: #45b300;
$alert-red: #b30100;

// Contrast
$white: #fff;
$black: #000;

// Vendor
$messenger-blue: #007fff;
$groupon-green: #23992e;

// Materialize colors
$grey-lighten-4: #f5f5f5;

//Neons
$neon-pink: #C445FF;
$neon-yellow: #F7EA06;
$neon-blue: #0066CC;
$neon-green: #06EFB2;
$neon-purple: #0033CC;

// Plan Colors
$plan-silver: #C4CDD5;
$plan-silver-dark: #919EAA;
$plan-gold: #FFE9A0;
$plan-gold-dark: #D4AF37;
$plan-platinum: #9FBDD7;
$plan-platinum-dark: #253D53;
$plan-platinum-plus: #5DEDCC;
$plan-platinum-plus-dark: #00B188;

$plan-card-blue: #4d8fd6;

// Gears
$gear-yellow: #F6A71C;
$gear-blue: #0066CC;
$gear-pink: #C445FF;

// Box Shadows
$box-shadow: inset 0 1px 0 rgba($white, .15), 0 2px 5px rgba($black, .075) !default;
$box-shadow-upward: inset 0 1px 0 rgba($white, .15), 2px 0 5px rgba($black, .075);
$border-box-radius: 4px;
$border-btn-radius: 99px;

// Media Screen Sizes
$extra-small-screen: 320px !default;
$hvga-screen: 480px !default;
$small-screen: 600px !default;
$small-screen-up: 601px !default;
$ipad-max: 767px !default;
$ipad-min: 768px !default;
$medium-screen: 992px !default;
$medium-screen-up: 993px !default;
$large-screen: 1200px !default;
$large-screen-up: 1201px !default;
$extra-large-screen: 1440px !default;
$extra-large-screen-up: 1441px !default;
$huge-screen: 1900px !default;
$vuetify-sm: 600px;
$vuetify-md: 960px;
$vuetify-lg: 1264px;
$vuetify-xl: 1904px;
