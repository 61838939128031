.card {
  border-radius: $border-box-radius;
  box-shadow: $box-shadow;
}

.card.mod-border {
  border: 1px $gray-lightest solid;
  box-shadow: 0 0 0;
}

.card-hd {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 10px 25px;
}

.card-hd-title {
  color: $white;
  font-size: 16px;
  font-weight: 400;
}

.card-hd.mod-recommended {
  background: $gold-light;
}

.card-hd.mod-customized {
  background: $blue-nav;
}

.card-body {
  padding: 30px;
}

.card-title {
  margin: 0 0 40px;
}

.card-title .card-icon {
  color: $blue-nav;
  float: left;
  font-size: 50px;
  margin: 0 20px 0 0;

  @media only screen and (max-width: $small-screen) {
    margin: 0 10px 0 0;
  }
}

.card-title h4 span {
  color: $gray-dark;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0 20px;
}

.card-title h6 {
  color: $gray-dark;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}

@media only screen and (max-width: $small-screen) {
  .card-body {
    padding: 15px;
  }

  .card.mod-border.mod-no-box-small {
    border: 0 !important;
  }

  .mod-no-box-small .card-body {
    padding: 0 !important;
  }

  .card-hd {
    padding: 5px 15px;
  }

  .card-hd-title {
    font-size: 14px;
  }

  .card-title {
    margin: 10px 0;
  }

  .card-title .card-icon {
    font-size: 20px;
  }

  .card-title h4 {
    font-size: 18px;
  }
}
